<template>
  <div class="app-container">
    <div class="content_data">
      <div
        style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
      >
        <div style="width:8%">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;">
            <div style="margin-bottom:5px">企业总数</div>
            <div>
              <span>{{ orgSum }}</span
              >家
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;">
            <div style="margin-bottom:5px">本月新增</div>
            <div>
              <span>{{ monthCount }}</span
              >家
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_select">
      <div>
       <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input
          placeholder="请输入公司名称/报税手机号"
          v-model="listQuery.name"
          style="width: 200px;margin: 0 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-input
          placeholder="请输入地区"
          v-model="listQuery.address"
          style="width: 100px;margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px;"
          icon="Search"
          >搜索</el-button
        >
        <el-select
          v-model="listQuery.isTrial"
          placeholder="请选择账号类型"
          size="small"
          filterable
          style="width:140px"
          @change="getList"
        >
          <el-option label="全部" :value="9"> </el-option>
          <el-option label="正常" :value="0"> </el-option>
          <el-option label="测试" :value="1"> </el-option>
          <el-option label="活动账号" :value="2"> </el-option>
          <el-option label="渠道" :value="3"> </el-option>
          <el-option label="内部" :value="4"> </el-option>
          <el-option label="vip" :value="5"> </el-option>
        </el-select>
        <el-select
          v-model="listQuery.status"
          placeholder="请选择使用状态"
          size="small"
          filterable
          style="width:140px"
          @change="getList"
        >
          <el-option label="全部" :value="9"> </el-option>
          <el-option label="正常使用" :value="1"> </el-option>
          <el-option label="试用" :value="2"> </el-option>
          <el-option label="停用" :value="3"> </el-option>
          <el-option label="活动账号" :value="4"> </el-option>
        </el-select>

        <!-- <selectcity v-model:citys="listQuery.districtCode" style="width:140px" @change="getList"></selectcity> -->
      </div>

      <div></div>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="index" label="序号" width="55" align="center" />
        <el-table-column prop="name" label="签约时间" min-width="120" sortable>
          <template #default="scope">
            <span>{{ $parseTime(scope.row.countTime, "{y}-{m}-{d}") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地区" min-width="120">
        </el-table-column>
        <el-table-column
          prop="name"
          label="公司名称"
          min-width="280"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div style="display: flex;align-items: center;">
              <div
                style="width: 90%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              >
                <span>{{ scope.row.name }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i
                  class="iconfont icon-fuzhi1"
                  style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                  @click="$copyComName(scope.row.name)"
                >
                </i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="manager" label="联系人" min-width="120">
        </el-table-column>
        <el-table-column prop="managerTel" label="联系电话" min-width="120">
        </el-table-column>
        <!-- <el-table-column label="其他联系人" min-width="120">
          <template #default="scope"> </template>
        </el-table-column> -->
        <el-table-column prop="count" label="账套数" min-width="120" sortable>
        </el-table-column>
        <el-table-column
          prop="countUseTime"
          label="到期时间"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column prop="saleName" label="销售经理" min-width="100">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="120">
        </el-table-column>
        <el-table-column label="账号类型" align="center" width="90">
          <template #default="scope">
            <div v-if="scope.row.is_trial == 0">正常</div>
            <div v-if="scope.row.is_trial == 1">测试</div>
            <div v-if="scope.row.is_trial == 2">活动账号</div>
            <div v-if="scope.row.is_trial == 3">渠道</div>
            <div v-if="scope.row.is_trial == 4">内部</div>
            <div v-if="scope.row.is_trial == 5">vip</div>
          </template>
        </el-table-column>
        <el-table-column label="账号状态" align="center" width="80">
          <template #default="scope">
            <span v-if="scope.row.status == 1" style="color:green">启用</span>
            <span v-else style="color:red">停用</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
</template>

<script>
import { customerMaintainList, statisticsOrgList } from "@/api/maintain";
import selectcity from "@/components/Screening/selectcity";

export default {
  name: "customerList",
  components: {
    selectcity
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        status: 9,
        isTrial: 9,
      },
      loading: false,
      tableData: [],
      total: 0,
      orgSum: 0,
      monthCount: 0,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(280);
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      customerMaintainList({}).then((res) => {
        if (res.data.msg == "success") {
          this.orgSum = res.data.data.orgSum;
          this.monthCount = res.data.data.monthCount;
        }
      });
    },
    getList() {
      this.loading = true;
      statisticsOrgList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 98%;
  margin: 0 auto;
}
.content_data {
  background-color: #d8ecff;
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont {
  font-size: 18px;
  color: #17a2b8;
}
.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-input){
  height: 24px;
  line-height: 24px;
}
</style>
